import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import SEO from '../components/seo'

import '../css/photo.scss'

const PhotoPage = ({ data }) => (
  <div className="grid photo-page gap-8 grid-cols-1 lg:grid-cols-2">
    <SEO title={data.photo.title} />
    <Img className="overflow-hidden rounded"
      fluid={data.photo.image.localFile.childImageSharp.fluid} />
    <div className="text-content style-text text-xl">
      <h1 className="text-3xl font-semibold mb-4">{data.photo.title}</h1>
      <div className="mb-4 text-gray-500 mr-2 font-semibold uppercase text-sm">
        <span>{ new Date(data.photo.createdAt).toDateString() }</span>
      </div>
      <section className="mb-4">
        {documentToReactComponents(data.photo.description.json)}
      </section>
      <div>
        {data.photo.tags && data.photo.tags.map(tag => {
          return <Link className="tag-link" to={`/photography/${tag.fields.slug}/`} key={tag.id}>{tag.title}</Link>
        })}
      </div>
    </div>
  </div>
)

export const query = graphql`
  query PhotoPageQuery($id: String) {
    photo: contentfulPhoto(id: {eq: $id}) {
      id
      title
      createdAt
      tags {
        id
        title
        fields {
          slug
        }
      }
      description {
        json
      }
      image {
        id
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PhotoPage
